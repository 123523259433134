import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import Footer from '../components/Footer'
import Header from '../components/Header'

const PaymentLog = () => {
  const [response, setResponse] = useState('');
  const [formData, setFormData] = useState({
    cardNumber: '',
    billingFirstName: '',
    billingLastName: '',
    billingCompanyName: '',
    cardExpMonth: '',
    cardExpYear: '',
    cvv: '',
    cardCvv: '',
    avs: '',
    billingAddress: '',
    billingZip: '',
  });
  useEffect(() => {
    // Define the API request configuration
    var myHeaders = new Headers();
    myHeaders.append("X-PJ-Application-Key", "c66b8481-87d4-46d5-9adb-6e5720fa0e83");
    myHeaders.append("Authorization", "Basic dGl3YXJpLnNlcnZlc2hAZ21haWwuY29tIDpTYXJ2ZXNoQDIw");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    // Make the API request
    // fetch("https://api.payjunctionlabs.com/terminals", requestOptions)
    fetch("https://api.payjunction.com/terminals", requestOptions)
      .then(response => response.text())
      .then(result => {
        // Handle the API response here
        console.log(result);
      })
      .catch(error => {
        // Handle errors here
        console.log('error', error);
      });
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const myHeaders = new Headers();
    myHeaders.append("X-PJ-Application-Key", "c66b8481-87d4-46d5-9adb-6e5720fa0e83");
    myHeaders.append("Authorization", "Basic dGl3YXJpLnNlcnZlc2hAZ21haWwuY29tIDpTYXJ2ZXNoQDIw");
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams(formData);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
    };

    try {
      const response = await fetch("https://api.payjunction.com/transactions", requestOptions);
      const result = await response.text();
      setResponse(result);
    } catch (error) {
      console.log('error', error);
    }
  };


  return (
    <>
      <Header />
      <main id='main'>

        <section className="hero-section inner-page">
          <div className="wave">

            <svg width="1920px" height="265px" viewBox="0 0 1920 265" version="1.1">
              <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
                  <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,667 L1017.15166,667 L0,667 L0,439.134243 Z" id="Path"></path>
                </g>
              </g>
            </svg>

          </div>

          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="row justify-content-center">
                  <div className="col-md-12 text-center hero-text">
                    <h1 data-aos="fade-up" data-aos-delay="">Your Payment Logs</h1>
                    <p className="mb-5" data-aos="fade-up" data-aos-delay="100">Please contact Customer Support for any questions you may have regarding CoreLaser.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
        <section className="section my-5">
          <div className="container">


            <div className="row justify-content-center">

              <div className="col-md-8 mb-5 mb-md-0" data-aos="fade-up">
                <div>

                </div>
              </div>

            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default PaymentLog
