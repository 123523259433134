import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Footer from "../components/Footer";

const Privacy = () => {

  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);


  const login = () => {
    navigate('/sign-in')
  }
  const logout = () => {
    localStorage.removeItem("email")
    localStorage.removeItem("token")
    localStorage.removeItem("userID")
    navigate('/sign-in')

  }
  const handleMobileNav = () => {
    const navbar = document.querySelector('#navbar');
    const mobileNavToggle = document.querySelector('.mobile-nav-toggle');

    if (navbar && mobileNavToggle) {
      navbar.classList.toggle('navbar-mobile');
      mobileNavToggle.classList.toggle('bi-list');
      mobileNavToggle.classList.toggle('bi-x');
    }
  };
  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-center header-scrolled">
        <div className="container d-flex justify-content-between align-items-center">

          <div className="logo d-flex align-items-center">
            <Link to="/"><img src='assets/img/logo.png' alt="" className="img-fluid me-2" /></Link>
            <h1><Link to="/">CoreLaser</Link></h1>
          </div>

          {/* <nav id="navbar" className="navbar">
                    <ul>
                        <li><NavLink activeclassname="active" to="/">Home</NavLink></li>
                        <li><NavLink activeclassname="active" to="/about">About</NavLink></li>
                        <li><NavLink activeclassname="active" to="/pricing">Pricing</NavLink></li>
                        <li><NavLink activeclassname="active" to="/contact">Contact</NavLink></li>
                       
                       {
                        localStorage.getItem("email")
                        ?
                        <li className="dropdown"><a href="#"><span>{localStorage.getItem("username")}</span> <i className="bi bi-chevron-down"></i></a>
                            <ul>
                               
                                <li className='logoutbtn' onClick={()=>logout()}>Logout</li>
                            </ul>
                        </li>
                        :
                        <li>
                            <button className='ms-2 btn btn-outline-login' style={{ padding: '5px 15px !important' }} onClick={login}>Sign In</button>
                        </li>
                       }
                        
                        
                    </ul>
                    <i className="bi bi-list mobile-nav-toggle" onClick={handleMobileNav}></i>
                </nav> */}

        </div>
      </header>
      <main id="main">
        <section className="section my-5 pb-5">
          <div className="container mt-5">
            <div className="row">
              <div className="col-md-12 mb-5">
                <h2>Privacy Policy</h2>
                <p>
                  We couldn't find the page you were looking for. This is either
                  because:
                </p>
                <ul>
                  <li>
                    There is an error in the URL entered into your web browser.
                    Please check the URL and try again.
                  </li>
                  <li>
                    The page you are looking for has been moved or deleted.
                  </li>
                </ul>
                <p>
                  You can return to our homepage by clicking here, or you can
                  try searching for the content you are seeking by clicking
                  here.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <Footer /> */}
      <div className="row justify-content-center text-center">
        <div className="col-md-7">
          <p className="copyright">&copy; {new Date().getFullYear()} Copyright CoreLaser. All Rights Reserved</p>
        </div>
      </div>
    </>
  );
};

export default Privacy;
