import React, { useEffect, useState } from 'react'
import CTA from '../components/CTA'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Testimonials from '../components/Testimonials'
import { Link, useNavigate } from 'react-router-dom'
import { membershipList } from '../utils/helper'

const Pricing = () => {

    const navigate = useNavigate();
    const [plans, setPlans] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0);
        getPlans();
    }, []);


    const getPlans = () => {
        membershipList("show-web")
            .then((res) => {
                if (res.success === true) {
                    console.log("getting membership data", res);
                    setPlans(res.result);
                } else {
                    console.log("RES : ", res);
                }
            })
            .catch((e) => {
                console.log("response : ", e);
            });
    }


    const takeToTop = (redirectTo) => {

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
        if (redirectTo === "home") {
            navigate('/')
        } else if (redirectTo === "about") {
            navigate('/about')
        } else if (redirectTo === "pricing") {
            navigate('/pricing')
        } else {
            navigate('/contact')
        }
    }



    return (
        <>
            <Header takeToUp={takeToTop} />
            <main id="main">
                <section className="hero-section inner-page">
                    <div className="wave">

                        <svg width="1920px" height="265px" viewBox="0 0 1920 265" version="1.1">
                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
                                    <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,667 L1017.15166,667 L0,667 L0,439.134243 Z" id="Path"></path>
                                </g>
                            </g>
                        </svg>

                    </div>

                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <div className="row justify-content-center">
                                    <div className="col-md-7 text-center hero-text">
                                        <h1 data-aos="fade-up" data-aos-delay="">Pricing</h1>
                                        <p className="mb-5" data-aos="fade-up" data-aos-delay="100">SUBSCRIBE TO MEMBERSHIP PLANS</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="section my-5 pb-5">
                    <div className="container">

                        <div className="row">
                            <div className="col-md-6 mb-5">
                                <p>Your journey towards a healthier, more active life continues. Access your personalized waistline exercises and track your progress with just a few clicks. We're excited to see how far you've come!</p>
                                <p>Log in now and let's keep moving forward with CoreLaser. Your personal digital physiotherapist is ready when you are.</p>
                                <p>Are you ready to elevate your CoreLaser experience? With CoreLaser Premium, you unlock unlimited access to our range of advanced features and resources tailored to support and accelerate your physiotherapy journey.</p>
                                <p>With CoreLaser Premium, you will benefit from:</p>
                                <ul>
                                    <li>A broader selection of personalized waistline exercises</li>
                                    <li>Real-time, personalized feedback on your performance</li>
                                    <li>Detailed tracking and comprehensive performance reports</li>
                                    <li>Immediate access to all new features and updates</li>
                                    <li>Exclusive premium-only content and resources</li>
                                </ul>
                            </div>
                            <div className="col-md-6" data-aos="fade-left">
                                <img src="assets/img/about.jpeg" alt="Image" className="img-fluid" />
                            </div>
                        </div>
                        <div className="row align-items-stretch">
                            <div className="col-md-12 my-5 text-center">
                                <h2 className="section-heading">Choose A Plan</h2>
                                <p>We offer flexible subscription plans based on duration to fit your lifestyle and physiotherapy needs</p>

                            </div>

                            {plans.map((plan, index) => {
                                const currPlan = localStorage.getItem('plan');
                                return (

                                    <div className="col-lg-4 mb-4 mb-lg-0" key={index}>
                                        <div className={`pricing h-100 text-center ${localStorage.getItem('plan') === plan.plan_name ? "popular" : ""}`}>
                                            {plan.plan_name === currPlan ? <span className="popularity">Most Popular</span> : <span className="popularity">Best Value</span>}

                                            <h3>{plan.plan_name}</h3>
                                            <ul className="list-unstyled">
                                                <li>{plan.duration === 0 ? "Lifetime free" : plan.duration + " Months"} Membership</li>
                                                <li>{plan.benefits}</li>
                                            </ul>
                                            <div className="price-cta">
                                                {
                                                    localStorage.getItem('plan') === plan.plan_name
                                                        ?
                                                        <>
                                                            <strong className="price">$ {plan.membership_fee}</strong>
                                                            <p><Link to="/" className="btn btn-white">Start Workout</Link></p>
                                                        </>

                                                        :

                                                        plan.membership_fee === 0 ?
                                                            <>
                                                                <strong className="price">Free</strong>
                                                                <p><Link to="/" className="btn btn-white">Start Workout</Link></p>
                                                            </>
                                                            :
                                                            <>

                                                                <strong className="price">$ {plan.membership_fee}</strong>

                                                                <button className="btn btn-white" onClick={() => navigate('/subscribe-now', { state: { plan_name: plan.plan_name, amount: plan.membership_fee, plan: plan._id } })}> Buy Now</button>
                                                            </>
                                                }

                                            </div>
                                        </div>
                                    </div>)
                            }
                            )}









                            {/* <div className="col-lg-4 mb-4 mb-lg-0">
                                <div className={`pricing h-100 text-center ${localStorage.getItem('plan') === "Monthly" ? "popular" : ""}`}>
                                    <span className="popularity">Most Popular</span>
                                    <h3>Monthly</h3>
                                    <ul className="list-unstyled">
                                        <li>Monthly membership</li>
                                        <li>with recurring billing $5 per month</li>

                                    </ul>
                                    <div className="price-cta">
                                        {
                                            localStorage.getItem('plan') === "Monthly"
                                                ?
                                                <>
                                                    <strong className="price">Monthly</strong>
                                                    <p><Link to="/" className="btn btn-white">Start Workout</Link></p>
                                                </>

                                                :
                                                <>
                                                    <strong className="price">$5.00/Month</strong>
                                                    <button className="btn btn-white" onClick={() => navigate('/subscribe-now', { state: { plan_name: "Monthly", amount: 5 } })}> Buy Now</button>
                                                </>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4 mb-lg-0">
                                <div className={`pricing h-100 text-center ${localStorage.getItem('plan') === "Quarterly" ? "popular" : ""}`}>
                                    <span className="popularity">Best Value</span>
                                    <h3>Quarterly</h3>
                                    <ul className="list-unstyled">
                                        <li>Quarterly Membership</li>
                                        <li>with recurring billing $12 per quarter</li>
                                    </ul>
                                    <div className="price-cta">
                                        {
                                            localStorage.getItem('plan') === "Quarterly"
                                                ?
                                                <>
                                                    <strong className="price">Quarterly</strong>
                                                    <p><Link to="/" className="btn btn-white">Start Workout</Link></p>
                                                </>

                                                :
                                                <>
                                                    <strong className="price">$12.00/Quarter</strong>
                                                    <button className="btn btn-white" onClick={() => navigate('/subscribe-now', { state: { plan_name: "Quarterly", amount: 12 } })}> Buy Now</button>
                                                </>
                                        }

                                      
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
                <Testimonials />
                <CTA />
            </main>
            <Footer takeToUp={takeToTop} />
        </>
    )
}

export default Pricing
