import React, { useState } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';

function ForgotPassword({ show, onHide, isLoading, handleSendOTP, error }) {
    const [email, setEmail] = useState('');


    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleForgotPassword = () => {
        handleSendOTP(email);

    };

    const closeEmail = () => {
        setEmail('')
        onHide();
    }

    return (
        <Modal show={show} onHide={closeEmail}>
            <Modal.Header closeButton>
                <Modal.Title>Forgot Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="email">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </Form.Group>
                </Form>
                {error && (
                    <Form.Text className='text-danger'>{error}</Form.Text>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeEmail}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleForgotPassword}>
                    {
                        isLoading
                            ?
                            <>
                                <Spinner size='sm' animation="border" /> {"Sending"} </> :

                            "Submit"
                    }

                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ForgotPassword;
