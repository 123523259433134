export const getTargetPath = (json) => {
    var elements = JSON.parse(json).elements;
    // console.log(elements);
    var path = new Path2D();
    for (var key in elements) {
        if (elements.hasOwnProperty(key)) {
            var element = elements[key];
            if (element.move) {
                var endPoint = element.move[0];
                path.moveTo(endPoint.x, endPoint.y);
            }
            else if (element.addLine) {
                var endPoint = element.addLine[0];
                path.lineTo(endPoint.x, endPoint.y);
            }
            else if (element.addCurve) {
                var endPoint = element.addCurve[0];
                var cp1 = element.addCurve[1];
                var cp2 = element.addCurve[2];
                path.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, endPoint.x, endPoint.y);
            }
            else if (element.closeSubpath) {
                path.closePath();
            }
            else {
                console.error("found unknown");
                console.error(element);
            }
        }
    }
    return path;
}

export let romvTarget = "\n{\n\t\"elements\": [{\n\t\t\t\"move\": [{\n\t\t\t\t\"x\": 1736,\n\t\t\t\t\"y\": 78.25\n\t\t\t}]\n\t\t},\n\t\t{\n\t\t\t\"addLine\": [{\n\t\t\t\t\"x\": 1736,\n\t\t\t\t\"y\": 2103.5799999999999\n\t\t\t}]\n\t\t}\n\t]\n}\n"
export let romhTarget = "\n{\n\t\"elements\": [{\n\t\t\t\"move\": [{\n\t\t\t\t\"x\": 544,\n\t\t\t\t\"y\": 1094.25\n\t\t\t}]\n\t\t},\n\t\t{\n\t\t\t\"addLine\": [{\n\t\t\t\t\"x\": 2932,\n\t\t\t\t\"y\": 1094.25\n\t\t\t}]\n\t\t}\n\t]\n}\n";
export let blueTarget = "\n{\n \"elements\" : [\n {\n \"move\" : [\n {\n \"x\" : 2387.3499999999999,\n \"y\" : 1092.05\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 1736.8,\n \"y\" : 1742.5999999999999\n },\n {\n \"x\" : 2387.3499999999999,\n \"y\" : 1451.3388439823898\n },\n {\n \"x\" : 2096.0888439823898,\n \"y\" : 1742.5999999999999\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 1086.25,\n \"y\" : 1092.05\n },\n {\n \"x\" : 1377.5111560176101,\n \"y\" : 1742.5999999999999\n },\n {\n \"x\" : 1086.25,\n \"y\" : 1451.3388439823898\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 1736.8,\n \"y\" : 441.5\n },\n {\n \"x\" : 1086.25,\n \"y\" : 732.76115601761001\n },\n {\n \"x\" : 1377.5111560176101,\n \"y\" : 441.5\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 2387.3499999999999,\n \"y\" : 1092.05\n },\n {\n \"x\" : 2096.0888439823898,\n \"y\" : 441.5\n },\n {\n \"x\" : 2387.3499999999999,\n \"y\" : 732.76115601761001\n }\n ]\n },\n {\n \"closeSubpath\" : true\n }\n ]\n }\n ";
export let greenTarget = "\n{\n \"elements\" : [\n {\n \"move\" : [\n {\n \"x\" : 1738,\n \"y\" : 1090.25\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 2452,\n \"y\" : 730.25\n },\n {\n \"x\" : 2038,\n \"y\" : 840.25\n },\n {\n \"x\" : 2232,\n \"y\" : 730.25\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 2932,\n \"y\" : 1094.25\n },\n {\n \"x\" : 2760,\n \"y\" : 730.25\n },\n {\n \"x\" : 2932,\n \"y\" : 900.25\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 2452,\n \"y\" : 1434.25\n },\n {\n \"x\" : 2932,\n \"y\" : 1288.25\n },\n {\n \"x\" : 2760,\n \"y\" : 1434.25\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 1738,\n \"y\" : 1090.25\n },\n {\n \"x\" : 2232,\n \"y\" : 1434.25\n },\n {\n \"x\" : 2038,\n \"y\" : 1340.25\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 1024,\n \"y\" : 730.25\n },\n {\n \"x\" : 1438,\n \"y\" : 840.25\n },\n {\n \"x\" : 1244,\n \"y\" : 730.25\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 544,\n \"y\" : 1094.25\n },\n {\n \"x\" : 716,\n \"y\" : 730.25\n },\n {\n \"x\" : 544,\n \"y\" : 900.25\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 1024,\n \"y\" : 1434.25\n },\n {\n \"x\" : 544,\n \"y\" : 1288.25\n },\n {\n \"x\" : 716,\n \"y\" : 1434.25\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 1738,\n \"y\" : 1090.25\n },\n {\n \"x\" : 1244,\n \"y\" : 1434.25\n },\n {\n \"x\" : 1438,\n \"y\" : 1340.25\n }\n ]\n },\n {\n \"closeSubpath\" : true\n }\n ]\n }\n";
export let redTarget = "\n{\n \"elements\" : [\n {\n \"move\" : [\n {\n \"x\" : 1737.3299999999999,\n \"y\" : 1091.5799999999999\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 1376,\n \"y\" : 462.25\n },\n {\n \"x\" : 1591.3299999999999,\n \"y\" : 923.58000000000004\n },\n {\n \"x\" : 1376,\n \"y\" : 716.25\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 1736,\n \"y\" : 78.25\n },\n {\n \"x\" : 1376,\n \"y\" : 240.25\n },\n {\n \"x\" : 1522,\n \"y\" : 78.25\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 2096,\n \"y\" : 462.25\n },\n {\n \"x\" : 1950,\n \"y\" : 78.25\n },\n {\n \"x\" : 2096,\n \"y\" : 240.25\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 1737.3299999999999,\n \"y\" : 1091.5799999999999\n },\n {\n \"x\" : 2096,\n \"y\" : 716.25\n },\n {\n \"x\" : 1883.3299999999999,\n \"y\" : 923.58000000000004\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 1376,\n \"y\" : 1719.5799999999999\n },\n {\n \"x\" : 1591.3299999999999,\n \"y\" : 1258.25\n },\n {\n \"x\" : 1376,\n \"y\" : 1465.5799999999999\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 1736,\n \"y\" : 2103.5799999999999\n },\n {\n \"x\" : 1376,\n \"y\" : 1941.5799999999999\n },\n {\n \"x\" : 1522,\n \"y\" : 2103.5799999999999\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 2096,\n \"y\" : 1719.5799999999999\n },\n {\n \"x\" : 1950,\n \"y\" : 2103.5799999999999\n },\n {\n \"x\" : 2096,\n \"y\" : 1941.5799999999999\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 1737.3299999999999,\n \"y\" : 1090.25\n },\n {\n \"x\" : 2096,\n \"y\" : 1465.5799999999999\n },\n {\n \"x\" : 1883.3299999999999,\n \"y\" : 1258.25\n }\n ]\n },\n {\n \"closeSubpath\" : true\n }\n ]\n }\n";
export let yellowTarget = "\n{\n \"elements\" : [\n {\n \"move\" : [\n {\n \"x\" : 1736.8,\n \"y\" : 1087.55\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 1087.25,\n \"y\" : 1737.0999999999999\n },\n {\n \"x\" : 1736.8,\n \"y\" : 1446.28655923259\n },\n {\n \"x\" : 1445.98655923259,\n \"y\" : 1737.0999999999999\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 437.70000000000005,\n \"y\" : 1087.55\n },\n {\n \"x\" : 728.51344076740997,\n \"y\" : 1737.0999999999999\n },\n {\n \"x\" : 437.70000000000005,\n \"y\" : 1446.28655923259\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 1087.25,\n \"y\" : 438\n },\n {\n \"x\" : 437.70000000000005,\n \"y\" : 728.81344076740993\n },\n {\n \"x\" : 728.51344076740997,\n \"y\" : 438\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 1736.8,\n \"y\" : 1087.55\n },\n {\n \"x\" : 1445.98655923259,\n \"y\" : 438\n },\n {\n \"x\" : 1736.8,\n \"y\" : 728.81344076740993\n }\n ]\n },\n {\n \"closeSubpath\" : true\n },\n {\n \"move\" : [\n {\n \"x\" : 3035.9499999999998,\n \"y\" : 1087.55\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 2386.3999999999996,\n \"y\" : 1737.0999999999999\n },\n {\n \"x\" : 3035.9499999999998,\n \"y\" : 1446.28655923259\n },\n {\n \"x\" : 2745.1365592325897,\n \"y\" : 1737.0999999999999\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 1736.8499999999997,\n \"y\" : 1087.55\n },\n {\n \"x\" : 2027.6634407674096,\n \"y\" : 1737.0999999999999\n },\n {\n \"x\" : 1736.8499999999997,\n \"y\" : 1446.28655923259\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 2386.3999999999996,\n \"y\" : 438\n },\n {\n \"x\" : 1736.8499999999997,\n \"y\" : 728.81344076740993\n },\n {\n \"x\" : 2027.6634407674096,\n \"y\" : 438\n }\n ]\n },\n {\n \"addCurve\" : [\n {\n \"x\" : 3035.9499999999998,\n \"y\" : 1087.55\n },\n {\n \"x\" : 2745.1365592325897,\n \"y\" : 438\n },\n {\n \"x\" : 3035.9499999999998,\n \"y\" : 728.81344076740993\n }\n ]\n },\n {\n \"closeSubpath\" : true\n }\n ]\n }\n";