import './App.css';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import io from 'socket.io-client';
import Home from './pages/Home';
import ConnectToApp from './pages/ConnectToApp';
import Level1 from './pages/Level1';
import Level2 from './pages/Level2';
import Level3 from './pages/Level3';
import Level4 from './pages/Level4';
import About from './pages/About';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import Signup from './pages/Signup';
import Signin from './pages/Signin';
import Level5 from './pages/Level5';
import Level6 from './pages/Level6';
import Terms from './pages/Terms';
import Business from './pages/Business';
import Privacy from './pages/Privacy';
import SubscribNow from './pages/SubscribNow';
import PaymentLog from './pages/PaymentLog';
import ThankYou from './pages/ThankYou';


const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/pricing" element={<Pricing />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/sign-up" element={<Signup />} />
        <Route exact path="/sign-in" element={<Signin />} />

        <Route exact path="/connect-to-app/android/:id" element={<ConnectToApp />} />
        <Route exact path="/connect-to-app/ios/:id" element={<ConnectToApp />} />

        <Route exact path="/level1/android/:id" element={<Level1 />} />
        <Route exact path="/level1/ios/:id" element={<Level1 />} />

        <Route exact path="/level2/android/:id" element={<Level2 />} />
        <Route exact path="/level2/ios/:id" element={<Level2 />} />

        <Route exact path="/level3/android/:id" element={<Level3 />} />
        <Route exact path="/level3/ios/:id" element={<Level3 />} />

        <Route exact path="/level4/android/:id" element={<Level4 />} />
        <Route exact path="/level4/ios/:id" element={<Level4 />} />

        <Route exact path="/level5/android/:id" element={<Level5 />} />
        <Route exact path="/level5/ios/:id" element={<Level5 />} />

        <Route exact path="/level6/android/:id" element={<Level6 />} />
        <Route exact path="/level6/ios/:id" element={<Level6 />} />

        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/privacy-policy" element={<Privacy />} />

        <Route exact path="/business" element={<Business />} />
        <Route exact path="/subscribe-now" element={<SubscribNow />} />
        <Route exact path="/payment-log" element={<PaymentLog />} />
        <Route exact path="/payment-success" element={<ThankYou />} />


      </Routes>
    </Router>
  );
  // const [messages, setMessages] = useState([]);
  // useEffect(() => {
  //   socket.on('connect', () => {
  //     console.log('Connected to server');
  //   });
  //   socket.on('newMessage', (data) => {
  //     console.log('Received message:', data);
  //     setMessages([...messages, data]);
  //   });
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  // let sendData = () => {
  //   socket.emit('newMessage', {
  //     X: '12.2',
  //     Y: '88.356',
  //     Z: '45.32'
  //   });
  // }

  // return (
  //   <div className="App">
  //     <h1>this is socket demo</h1>
  //     {/* {messages} */}
  //     <button type='button' onClick={() => sendData()}>send data</button>
  //   </div>
  // );
}

export default App;
