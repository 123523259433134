import React, { useState } from 'react'
import { useEffect } from 'react';
import { io } from 'socket.io-client';
import Canvas1 from '../components/Canvas1'
import { socket } from './Home';
import { useNavigate } from 'react-router-dom';
let getFirstData = 0
const Level1 = () => {
    const navigate = useNavigate()
    const [data, setData] = useState({ x: 0, y: 0 })
    const [difficulty, setDifficulty] = useState(1)
    const [device, setDevice] = useState('')
    let [calibrationData, setCalibrationData] = useState({ x: 0, y: 0 })
    useEffect(() => {
        if(!localStorage.getItem("email")){
            navigate('/sign-in')
        }

        if (window.location.href.match("android") != null) {
            console.log(window.location.href.match("android"),"if else")

            socket.on('difficulty-android'+localStorage.getItem('userID'), (data) => {
                console.log(data, 'difficulty in lebeval');
                setDifficulty(data)
            });
            socket.on('calibrate-android', (data) => {
                setDevice('android')
                console.log('calibrate-android', 'calibrate');
                if (getFirstData === 0) {
                    setCalibrationData(data)
                    getFirstData = 1
                }
                setData(data)
                console.log(data,'data');
            });
            socket.on('disconnect-level-android', () => {
            //   navigate('/')
              window.close()
            });
        } 
        if(window.location.href.match("ios")) {
            console.log(window.location.href.match("ios"),"else")
            socket.on('difficulty-ios'+localStorage.getItem('userID'), (data) => {
                console.log(data, 'difficulty in lebeval');
                setDifficulty(data)
            });
            socket.on('calibrate-ios', (data) => {
                setDevice('ios')
                // console.log(data, 'calibrate');
                if (getFirstData === 0) {
                    setCalibrationData(data)
                    getFirstData = 1
                }
                setData(data)
            });
            socket.on('disconnect-level-ios', () => {

                window.close()

            });
        }

        return () => {
            getFirstData = 0
        }
    }, []);
    console.log(difficulty, "bar");
    return (
        <Canvas1 dotData={data} device={device} difficulty={difficulty} calibrationData={calibrationData} />
    )
}

export default Level1
