import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';
const Header = ({ takeToUp }) => {
    const navigate = useNavigate()
    useEffect(() => {

        const headerScrolled = () => {
            const selectHeader = document.querySelector('#header');
            if (selectHeader) {
                if (window.scrollY > 40) {
                    selectHeader.classList.add('header-scrolled');
                } else {
                    selectHeader.classList.remove('header-scrolled');
                }
            }
        };

        window.addEventListener('load', headerScrolled);
        window.addEventListener('scroll', headerScrolled);

        // Clean up the event listeners when the component unmounts
        return () => {
            window.removeEventListener('load', headerScrolled);
            window.removeEventListener('scroll', headerScrolled);
        };
    }, []);
    const login = () => {
        navigate('/sign-in')
    }
    const logout = () => {
        localStorage.removeItem("email")
        localStorage.removeItem("token")
        localStorage.removeItem("userID")
        localStorage.removeItem("username")
        localStorage.removeItem("plan")
        navigate('/sign-in')

    }
    const handleMobileNav = () => {
        const navbar = document.querySelector('#navbar');
        const mobileNavToggle = document.querySelector('.mobile-nav-toggle');

        if (navbar && mobileNavToggle) {
            navbar.classList.toggle('navbar-mobile');
            mobileNavToggle.classList.toggle('bi-list');
            mobileNavToggle.classList.toggle('bi-x');
        }
    };
    return (
        <header id="header" className="fixed-top d-flex align-items-center ">
            <div className="container d-flex justify-content-between align-items-center">

                <div className="logo d-flex align-items-center">
                    <Link to="/"><img src='assets/img/logo.png' alt="" className="img-fluid me-2" /></Link>
                    <h1><Link to="/">CoreLaser</Link></h1>
                </div>

                <nav id="navbar" className="navbar">
                    <ul>
                        <li><NavLink activeclassname="active" to='/' onClick={() => takeToUp('home')}>Home</NavLink></li>
                        <li><NavLink activeclassname="active" to='/about' onClick={() => takeToUp('about')}>About</NavLink></li>
                        <li><NavLink activeclassname="active" to='/pricing' onClick={() => takeToUp('pricing')}>Pricing</NavLink></li>
                        <li><NavLink activeclassname="active" to='/contact' onClick={() => takeToUp('contact')}>Contact</NavLink></li>

                        {/* <li onClick={() => takeToUp('home')}>Home</li>
                                        <li onClick={() => takeToUp('about')}>About</li>
                                        <li onClick={() => takeToUp('pricing')}>Pricing</li>
                                        <li onClick={() => takeToUp('contact')}>Contact</li> */}

                        {
                            localStorage.getItem("email")
                                ?
                                <li className="dropdown"><a href="#"><span>{localStorage.getItem("username")}</span> <i className="bi bi-chevron-down"></i></a>
                                    <ul>
                                        {/* <li><Link to="/payment-log">Payment Log</Link></li> */}
                                        <li className='logoutbtn' onClick={() => logout()}>Logout</li>
                                    </ul>
                                </li>
                                :


                                <li>
                                    <button className='ms-2 btn btn-outline-login' style={{ padding: '5px 15px !important' }} onClick={login}>Login</button>
                                </li>


                        }


                    </ul>
                    <i className="bi bi-list mobile-nav-toggle" onClick={handleMobileNav}></i>
                </nav>

            </div>
        </header>
    )
}

export default Header
