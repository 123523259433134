import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

function Instructions({ show, onHide }) {

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>How to Use the Movement-Based Gym System</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <video width="100%" height="400" controls>
                        <source src="/video.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

                <p>Welcome to our movement-based gym system! Here are some instructions on how to use it:</p>
                <ul>
                    <li>Choose your exercise routine from the available options.</li>
                    <li>Follow the on-screen instructions for each exercise.</li>
                    <li>Ensure your device's camera and motion sensors are enabled for accurate tracking.</li>
                    <li>Complete the exercise movements as shown on the screen.</li>
                    <li>Track your progress and achievements within the app.</li>
                </ul>
                <p>If you have any questions or need assistance, please contact our support team.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default Instructions;
