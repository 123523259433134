import React, { useEffect } from 'react';
import { NavLink } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

const PaymentSuccessPage = ({ history }) => {

    const navigate = useNavigate()

    const { state } = useLocation();

    useEffect(() => {
        localStorage.setItem('plan', state?.paymentDtails?.result?.plan?.plan_name)
    }, [])

    const login = () => {
        navigate('/sign-in')
    }
    const logout = () => {
        localStorage.removeItem("email")
        localStorage.removeItem("token")
        localStorage.removeItem("userID")
        navigate('/sign-in')

    }
    const handleMobileNav = () => {
        const navbar = document.querySelector('#navbar');
        const mobileNavToggle = document.querySelector('.mobile-nav-toggle');

        if (navbar && mobileNavToggle) {
            navbar.classList.toggle('navbar-mobile');
            mobileNavToggle.classList.toggle('bi-list');
            mobileNavToggle.classList.toggle('bi-x');
        }
    };



    return (

        <>
            <header id="header" className="fixed-top d-flex align-items-center header-scrolled">
                <div className="container d-flex justify-content-between align-items-center">

                    <div className="logo d-flex align-items-center">
                        <Link to="/"><img src='assets/img/logo.png' alt="" className="img-fluid me-2" /></Link>
                        <h1><Link to="/">CoreLaser</Link></h1>
                    </div>

                    <nav id="navbar" className="navbar">
                        <ul>
                            <li><NavLink activeclassname="active" to="/">Home</NavLink></li>
                            <li><NavLink activeclassname="active" to="/about">About</NavLink></li>
                            <li><NavLink activeclassname="active" to="/pricing">Pricing</NavLink></li>
                            <li><NavLink activeclassname="active" to="/contact">Contact</NavLink></li>

                            {
                                localStorage.getItem("email")
                                    ?
                                    <li className="dropdown"><a href="#"><span>{localStorage.getItem("username")}</span> <i className="bi bi-chevron-down"></i></a>
                                        <ul>
                                            {/* <li><Link to="/payment-log">Payment Log</Link></li> */}
                                            <li className='logoutbtn' onClick={() => logout()}>Logout</li>
                                        </ul>
                                    </li>
                                    :
                                    <li>
                                        <button className='ms-2 btn btn-outline-login' style={{ padding: '5px 15px !important' }} onClick={login}>Sign In</button>
                                    </li>
                            }


                        </ul>
                        <i className="bi bi-list mobile-nav-toggle" onClick={handleMobileNav}></i>
                    </nav>

                </div>
            </header>
            {console.log("Data REcieved ---> ", state)}
            <div className="container mt-5 py-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body text-center">
                                <img src="assets/img/icons8-success.gif" height="75" className='m-4' />
                                <h2 className="text-success">Payment Successful!</h2>
                                <p className="mt-3">
                                    Thank you for your payment. Your transaction was successful.
                                </p>
                                <p>
                                    Transaction ID: <strong>{state?.paymentDtails?.result?.Payment_Response?.transactionId}</strong>
                                </p>

                                <p>
                                    Total Payment: <strong>${state?.paymentDtails?.result?.Payment_Response?.amountTotal}</strong>
                                </p>

                                <p>
                                    Date of Payment: <strong>{moment(state?.paymentDtails?.result?.Payment_Date).format("Do MMM YY")}</strong>
                                </p>
                                <p>
                                    Plan: <strong>{state?.paymentDtails?.result?.plan?.plan_name} ({" From " + moment(state?.paymentDtails?.result?.Payment_Date).format("Do MMM YY") + " to " + moment(state?.paymentDtails?.result?.Expiry_Date).format("Do MMM YY")})</strong>
                                </p>
                                <p className="text-muted">An email receipt has been sent to your email address.</p>
                                <button
                                    className="btn btn-primary mt-3"
                                    onClick={() => {
                                        // Redirect to home or any other page
                                        navigate('/')
                                    }}
                                >
                                    Back to Home
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PaymentSuccessPage;
