import { blueTarget, getTargetPath, greenTarget, redTarget, romhTarget, romvTarget, yellowTarget } from "../utils/getTargetPath";

var Target = (function () {
    function Target(path, color, drawXAxis, drawYAxis) {
        if (path === void 0) { path = new Path2D(); }
        if (color === void 0) { color = "white"; }
        if (drawXAxis === void 0) { drawXAxis = true; }
        if (drawYAxis === void 0) { drawYAxis = true; }
        this.path = path;
        this.color = color;
        this.drawXAxis = drawXAxis;
        this.drawYAxis = drawYAxis;
    }
    Target.romv = function () {
        var path = getTargetPath(romvTarget);
        return new Target(path, "#005493", true, true);
    };
    Target.romh = function () {
        var path = getTargetPath(romhTarget);
        return new Target(path, "#009193", true, true);
    };
    Target.blue = function () {
        var path = getTargetPath(blueTarget);
        return new Target(path, "#3498db", true, true);
    };
    Target.green = function () {
        var path = getTargetPath(greenTarget);
        return new Target(path, "#2ecc71", true, true);
    };
    Target.yellow = function () {
        var path = getTargetPath(yellowTarget);
        return new Target(path, "#f1c40f", true, true);
    };
    Target.red = function () {
        var path = getTargetPath(redTarget);
        return new Target(path, "#e74c3c", true, true);
    };
    return Target;
}());
export default Target