import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { socket } from './Home';
const ConnectToApp = () => {
    const [countdown, setCountdown] = useState(5);
    const [dots, setDots] = useState('.');
    const navigate = useNavigate()

    //     socket.on('level-android', (data) => {
    //     console.log('level-android :',data);
    //     navigate('/level')
    // });
    // socket.on('level2', () => {
    //     console.log('level 2');
    //     navigate('/level2')
    // });
    // socket.on('level3', () => {
    //     console.log('level 3');
    //     navigate('/level3')
    // });
    useEffect(() => {
        if (!localStorage.getItem("email")) {
            navigate('/sign-in')
        }

        const interval = setInterval(() => {
            setDots(prevDots => {
                if (prevDots.length < 3) {
                    return prevDots + '.';
                } else {
                    return '.';
                }
            });
        }, 500);
        const timer = setTimeout(() => {
            if (countdown > 0) {
                setCountdown(countdown - 1);
            }
        }, 1000);
        socket.on('level-android' + localStorage.getItem('userID'), (data) => {
            console.log('level-android', data);
            // navigate('/level1')
            if (data.level === 'Level 1') {
                navigate(`/level1/android/${data.userId}`)
            }
            if (data.level === 'Level 2') {
                navigate(`/level2/android/${data.userId}`)
            }
            if (data.level === 'Level 3') {
                navigate(`/level3/android/${data.userId}`)
            }
            if (data.level === 'Level 4') {
                navigate(`/level4/android/${data.userId}`)
            }
            if (data.level === 'Level 5') {
                navigate(`/level5/android/${data.userId}`)
            }
            if (data.level === 'Level 6') {
                navigate(`/level6/android/${data.userId}`)
            }
        });

        socket.on('level-ios' + localStorage.getItem('userID'), (data) => {
            console.log('level-ios', data);
            // navigate('/level1')
            if (data.level === 'Level 1') {
                navigate(`/level1/ios/${data.userId}`)
            }
            if (data.level === 'Level 2') {
                navigate(`/level2/ios/${data.userId}`)
            }
            if (data.level === 'Level 3') {
                navigate(`/level3/ios/${data.userId}`)
            }
            if (data.level === 'Level 4') {
                navigate(`/level4/ios/${data.userId}`)
            }
            if (data.level === 'Level 5') {
                navigate(`/level5/ios/${data.userId}`)
            }
            if (data.level === 'Level 6') {
                navigate(`/level5/ios/${data.userId}`)
            }
        });

        return () => {
            clearTimeout(timer)
            clearInterval(interval)
        }
    }, [countdown]);
    return (
        <div style={{ width: '100vw', height: '100vh', background: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ textAlign: 'center' }}>
                <h1 style={{ color: '#fff' }}>Calibrating<span style={{ marginLeft: '5px', display: 'inline-block', width: '30px', textAlign: 'left' }}>{dots}</span></h1>
                <hr />
                <h3 style={{ color: '#fff' }}>Display Name : {localStorage.getItem('username')}</h3>
                <p>Please hold your phone firmly against your belt line and then rotate it horizontal.</p>
                <div style={{ color: '#fff', fontSize: '48px', textAlign: 'center' }}>{countdown === 0 ? 'Press Get Started on Mobile!' : countdown}</div>
            </div>
        </div >
    )
}

export default ConnectToApp
