import React, { useRef, useEffect, useState } from 'react'
import Target from './Target'

const Canvas4 = props => {

    const canvasRef = useRef(null)
    const canvas = canvasRef.current
    const [dotPosition, setDotPosition] = useState({ x: 0, y: 0 });
    const [diff, setDiff] = useState(props?.difficulty)
    const mapValue = (value, inMin, inMax, outMin, outMax) => {
        return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    }

    useEffect(() => {
        setDiff(props?.difficulty)
        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')
        let target = Target.green();

        let path = target.path

        var lineWidth = 20;
        ctx.lineWidth = lineWidth;
        var centerX = canvas.width / 2;
        var centerY = canvas.height / 2;
        var scaleTic = 12;
        // 7=1,14=2,21=3,28=4,30=5,35=6    
        var scale = props?.difficulty;
        console.log(ctx, target);
        if (scale === undefined) {

            scale = 0;
        }
        var ticDistance = (centerY / scale) * scaleTic;
        ctx.strokeStyle = "black";
        if (!target.drawXAxis) {
            ctx.strokeStyle = "lightgrey";
        }
        ctx.fillStyle = ctx.strokeStyle;
        ctx.beginPath();
        ctx.moveTo(0, centerY);
        ctx.lineTo(canvas.width, centerY);
        ctx.closePath();
        ctx.stroke();
        ctx.beginPath();
        if (scale > 1) {
            var totalTics = centerX / ticDistance;
            drawDots(ctx, lineWidth, centerX, centerY, ticDistance, 0, totalTics);
            drawDots(ctx, lineWidth, centerX, centerY, -ticDistance, 0, totalTics);
        }
        ctx.closePath();
        ctx.fill();
        ctx.strokeStyle = "black";
        if (!target.drawYAxis) {
            ctx.strokeStyle = "lightgrey";
        }
        ctx.fillStyle = ctx.strokeStyle;
        ctx.beginPath();
        ctx.moveTo(centerX, 0);
        ctx.lineTo(centerX, canvas.height);
        ctx.closePath();
        ctx.stroke();
        ctx.beginPath();
        if (scale > 1) {
            var totalTics = centerY / ticDistance;
            drawDots(ctx, lineWidth, centerX, centerY, 0, ticDistance, totalTics);
            drawDots(ctx, lineWidth, centerX, centerY, 0, -ticDistance, totalTics);
        }
        ctx.closePath();
        ctx.fill();
        ctx.beginPath();
        ctx.arc(centerX, centerY, lineWidth, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.fillStyle = "black";
        ctx.fill();
        ctx.save();
        ctx.translate(209 - 4, 0);
        ctx.strokeStyle = "black";
        ctx.lineWidth = 80 * 2;
        ctx.lineCap = "round";
        ctx.stroke(path);
        ctx.strokeStyle = target.color;
        ctx.lineWidth = (80 - 10) * 2;
        ctx.stroke(path);
        ctx.restore();
        ctx.beginPath();

        let offsetX = window.innerWidth / 2;
        let offsetY = window.innerHeight / 2;
        if (props?.device === 'android') {
            // android data proper
            console.log(props?.device,"device");
         
            // let newX = offsetX + ((parseFloat(props?.dotData?.y)) * 6.6)
            // let newY = offsetY + ((parseFloat(props?.dotData?.x)) * 9.3)
            let devider;
            if(props?.difficulty == 7){
                devider = 1
            }else if(props?.difficulty == 14){
                devider = 2
            }else if(props?.difficulty == 21){
                devider = 3
            }else if(props?.difficulty == 28){
                devider = 4
            }else if(props?.difficulty == 35){
                devider = 5
            }else if(props?.difficulty == 42){
                devider = 6
            }
            console.log(devider,"div");
            let newX = offsetX + (((parseFloat(props?.dotData?.y)) * 40)/devider)
            let newY = offsetY + (((parseFloat(props?.dotData?.x)) * 50)/devider)
            setDotPosition({
                x: newY,
                y: newX
            });
        }

        if (props?.device === 'ios') {
            // ios data proper
            console.log(props?.device,"device");
            // let newX = offsetX + ((parseFloat(props?.dotData?.y)) * 6.6)
            // let newY = offsetY + ((parseFloat(props?.dotData?.x)) * 9.3)
            let devider;
            if(props?.difficulty == 7){
                devider = 1
            }else if(props?.difficulty == 14){
                devider = 2
            }else if(props?.difficulty == 21){
                devider = 3
            }else if(props?.difficulty == 28){
                devider = 4
            }else if(props?.difficulty == 35){
                devider = 5
            }else if(props?.difficulty == 42){
                devider = 6
            }
            console.log(devider,"div");
            let newX = offsetX + (((parseFloat(props?.dotData?.y)) * 40)/devider)
            let newY = offsetY + (((parseFloat(props?.dotData?.x)) * 50)/devider)
            setDotPosition({
                x: newY,
                y: newX 
            });
        }
    }, [props, dotPosition])

    function drawDots(ctx, weight, startX, startY, deltaX, deltaY, count) {
        for (var i = 0; i <= count; i++) {
            ctx.moveTo(startX, startY);
            ctx.arc(startX + i * deltaX, startY + i * deltaY, weight, 0, 2 * Math.PI);
        }
    }


    return <div id="" className="">
        {console.log(dotPosition, "data")}
        <div id="mainDisplayContainer" className="displayContainer text-center overflow-hidden" style={{ position: 'relative' }}>
            <canvas ref={canvasRef} id="canvas1" className="display" width="3883" height="2184" style={{ maxHeight: '100vh', width: 'auto' }}>

            </canvas>
            <div id="dot" style={{ position: 'absolute', top: dotPosition.x, left: dotPosition.y, transform: 'translate(-50%, -50%)', background: 'red', height: '25px', width: '25px', borderRadius: '50%', border: '2px solid #000', transition: `all ${0.5 * diff / 7}s` }}>

            </div>
            <div style={{ position: 'absolute', top: 0, right: 0, color: 'white', padding: '15px' }}>
                <p className='m-0'> Level: 4 </p>
                <p> Difficulty: {diff / 7} </p>
            </div>
        </div>
    </div>
}

export default Canvas4