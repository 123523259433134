import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CTA from '../components/CTA'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Testimonials from '../components/Testimonials'

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const navigate = useNavigate()
    const takeToTop = (redirectTo) => {

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
        if (redirectTo === "home") {
            navigate('/')
        } else if (redirectTo === "about") {
            navigate('/about')
        } else if (redirectTo === "pricing") {
            navigate('/pricing')
        } else {
            navigate('/contact')
        }
    }

    return (
        <>
            <Header takeToUp={takeToTop} />
            <main id="main">
                <section className="hero-section inner-page">
                    <div className="wave">

                        <svg width="1920px" height="265px" viewBox="0 0 1920 265" version="1.1">
                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
                                    <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,667 L1017.15166,667 L0,667 L0,439.134243 Z" id="Path"></path>
                                </g>
                            </g>
                        </svg>

                    </div>

                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <div className="row justify-content-center">
                                    <div className="col-md-7 text-center hero-text">
                                        <h1 data-aos="fade-up" data-aos-delay="">About Us</h1>
                                        <p className="mb-5" data-aos="fade-up" data-aos-delay="100">CoreLaser is a safe, low-impact way to heal that puts expert-level technology in the palm of your hand.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section className="section pb-5  my-5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 me-auto">
                                {/* <h2 className="mb-4">About Us</h2> */}
                                <p className="mb-4">Welcome to CoreLaser, where we are reshaping the future of physiotherapy, one waistline movement at a time.</p>
                                <p className="mb-4">Our journey began in 2023 with a simple but profound goal: To make physiotherapy accessible, effective, and engaging for everyone. Our founders, a team of experienced physiotherapists and tech innovators, observed a growing need for a solution that could make physiotherapy exercises more accessible and efficient for patients. From this observation, the concept of CoreLaser was born.</p>
                                <p className="">CoreLaser is a cutting-edge mobile application designed with the patient in mind. We understand that maintaining a regular exercise routine is essential for successful physiotherapy but can be challenging due to various factors. CoreLaser aims to overcome these challenges by providing a convenient and personalized exercise regimen that users can follow from the comfort of their homes.</p>
                            </div>
                            <div className="col-md-6" data-aos="fade-left">
                                <img src="assets/img/about.jpeg" alt="Image" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section pb-5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6" data-aos="fade-right">
                                <img src="assets/img/about_2.jpeg" alt="Image" className="img-fluid" />
                            </div>
                            <div className="col-md-6 me-auto">
                                {/* <h2 className="mb-4">About Us</h2> */}
                                <p className="mb-4">Our unique approach leverages the power of technology to monitor waistline movements, an essential component of many physiotherapy exercises. With the help of advanced motion detection algorithms, CoreLaser provides real-time feedback and tracks progress over time, helping users to improve their technique and stay motivated.</p>
                                <p className="mb-4">At CoreLaser, we believe that everyone deserves to live a life free from pain and physical limitations. Our mission is to empower individuals to take control of their recovery process and lead healthier, more active lives. We are committed to making physiotherapy exercises more engaging, manageable, and effective for all.</p>
                                <p className="mb-4">Backed by a team of dedicated professionals, we are constantly working to improve and expand our services to meet the evolving needs of our users. We pride ourselves on our innovative approach and are committed to pushing the boundaries of what is possible in the world of digital physiotherapy.</p>
                                <p className="mb-4">Join us on this exciting journey and discover how CoreLaser can transform your physiotherapy experience. Together, let's move towards a healthier, happier future.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <Testimonials />
                <CTA />
            </main>
            <Footer takeToUp={takeToTop} />
        </>
    )
}

export default About
