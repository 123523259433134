import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import OtpInput from 'react-otp-input';

function CheckOTP({ show, onHide, email, OTPverify, error }) {

    const [otp, setOTP] = useState('');

    const handleVerifyOTP = () => {

        if (otp.length === 4) {
            OTPverify({ email: email, otp: otp })

        } else {
            console.log('Invalid OTP');
        }
    };

    const customInputStyle = {
        width: '50px',
        height: '50px',
        fontSize: '24px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        margin: '20px 10px 20px 10px'
    };

    const closeOTP = () => {
        // alert("close")
        setOTP('');
        onHide();
    }

    return (
        <Modal show={show} onHide={closeOTP}>
            <Modal.Header closeButton>
                <Modal.Title>Enter OTP</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <Form>
                    <Form.Group controlId="otp">
                        <Form.Label>Enter 4-digit OTP</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter OTP"
                            value={otp}
                            onChange={handleOTPChange}
                            maxLength={4}
                        />
                    </Form.Group>
                </Form> */}
                <div className="d-flex justify-content-center">
                    <OtpInput
                        value={otp}
                        onChange={setOTP}
                        numInputs={4}
                        isInputNum={true}
                        separator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={customInputStyle}
                    />
                </div>
                {error && (
                    <Form.Text className='text-danger'>{error}</Form.Text>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => closeOTP}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleVerifyOTP}>
                    Verify OTP
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CheckOTP;
