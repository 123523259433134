import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getSetting } from '../utils/helper'

const Footer = ({ takeToUp }) => {


    const [link1, setImageLink1] = useState('')
    const [link2, setImageLink2] = useState('')

    useEffect(() => {
        setting();
    }, [])


    const setting = () => {
        getSetting('images')
            .then((res) => {
                if (res.success === true) {
                    console.log("run", res);
                    setImageLink1(res.image.imageLink1);
                    setImageLink2(res.image.imageLink2);
                } else {
                    console.log("RES : ", res);
                }
            })
            .catch((e) => {
                console.log("response : ", e);
            });
    }


    return (
        <>
            <footer className="footer" role="contentinfo">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mb-4 mb-md-0">
                            <h3>About CoreLaser</h3>
                            <p>Despite the overwhelming number of back pain solutions on the market, none genuinely understand how your body reacts to movement during the recovery process, but WE do. </p>
                            <p className="social">
                                <a className="me-1" href="https://twitter.com/corelaser" target="_blank">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        className="bi bi-twitter-x"
                                        style={{ width: '1em', height: '1em', marginTop: '1rem', marginBottom: '3.5rem' }}
                                    >
                                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                                    </svg>
                                </a>
                                <a className='me-1' href="https://www.facebook.com/CoreLaserByLaserGym/" target="_blank"><span className="bi bi-facebook"></span></a>
                                <a className='me-1' href="https://www.linkedin.com/company/laser-gym" target="_blank"><span className="bi bi-linkedin"></span></a>

                            </p>
                        </div>
                        <div className="col-md-7 ms-auto">
                            <div className="row site-section pt-0">
                                <div className="col-md-4 mb-4 mb-md-0">
                                    <h3>Navigation</h3>
                                    <ul className="list-unstyled">
                                        {/* <li onClick={() => takeToTop}><Link to="/">Home</Link></li>
                                        <li onClick={() => takeToTop}><Link to="/about">About</Link></li>
                                        <li onClick={() => takeToTop}><Link to="/pricing">Pricing</Link></li>
                                        <li onClick={() => takeToTop}><Link to="/contact">Contact</Link></li> */}

                                        <li className="c-pointer" onClick={() => takeToUp('home')}>Home</li>
                                        <li className="c-pointer" onClick={() => takeToUp('about')}>About</li>
                                        <li className="c-pointer" onClick={() => takeToUp('pricing')}>Pricing</li>
                                        <li className="c-pointer" onClick={() => takeToUp('contact')}>Contact</li>
                                    </ul>
                                </div>
                                <div className="col-md-4 mb-4 mb-md-0">
                                    <h3>Services</h3>
                                    <ul className="list-unstyled">
                                        <li><Link to="/terms">Terms and Condition</Link></li>
                                        {/* <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                             
                                        <li><Link to="/business">Business</Link></li> */}
                                    </ul>
                                </div>
                                <div className="col-md-4 mb-4 mb-md-0">
                                    <h3>Downloads</h3>

                                    {/* <ul className="list-unstyled w-75">
                                        <li><a href={link1} target="_blank"><img className='img-fluid' src='assets/img/apple.png' /></a></li>
                                        <li><a href={link2} target="_blank"><img className='img-fluid' src='assets/img/google.png' /></a></li>
                                    </ul> */}

                                    <ul className="list-unstyled w-75">
                                        <li><a href={link1} target="_blank" rel="noopener noreferrer"><img className='img-fluid' src='assets/img/apple.png' alt='Apple' /></a></li>
                                        <li><a href={link2} target="_blank" rel="noopener noreferrer"><img className='img-fluid' src='assets/img/google.png' alt='Google' /></a></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center text-center">
                        <div className="col-md-7">
                            <p className="copyright">&copy; {new Date().getFullYear()} Copyright CoreLaser. All Rights Reserved</p>
                        </div>
                    </div>

                </div>
            </footer>

            <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

        </>

    )
}

export default Footer
