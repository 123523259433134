import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import { testimonials } from "../utils/helper";
const Testimonials = () => {
    const sliderRef = useRef(null);

    const [testimonialData, setTestimonialData] = useState([])

    useEffect(() => {

        testimonials("testimonial/get-testimonial-web")
            .then((res) => {
                if (res.success === true) {
                    console.log("run", res);
                    setTestimonialData(res.result)
                } else {
                    console.log("RES : ", res);
                }
            })
            .catch((e) => {
                console.log("response : ", e);
            });
    }, []);

    return (
        <section className="section py-5 mt-5">
            <div className="container">
                <div className="row justify-content-center text-center mb-5">
                    <div className="col-md-12">
                        <h2 className="section-heading">Review From Our Users</h2>
                    </div>
                </div>
                <div className="row justify-content-center text-center">
                    <div className="col-md-7">
                        <Swiper
                            spaceBetween={30}
                            centeredSlides={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="testimonials-slider"
                        >
                            {testimonialData.map((t, index) => {
                                const filledStars = Array.from({ length: t.rating }, (_, index) => (
                                    <span key={index} className="bi bi-star-fill"></span>
                                ));
                                const emptyStars = Array.from({ length: 5 - t.rating }, (_, index) => (
                                    <span key={index} className="bi bi-star-fill muted"></span>
                                ));
                                return (
                                    <SwiperSlide>
                                        <div className="review text-center" key={index}>
                                            <p className="stars">
                                                {filledStars}
                                                {emptyStars}
                                            </p>
                                            <h3>{t.title}</h3>
                                            <blockquote>
                                                <p>{t.description}</p>
                                            </blockquote>

                                            <p className="review-user">
                                                <span className="d-block">
                                                    <span className="text-black">{t.name}</span>, &mdash; {t.role}
                                                </span>
                                            </p>

                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonials
