//Live
// const API_BASE_URL = 'https://51.79.85.211:3002/auth';
// const Payment_BASE_URL = 'https://51.79.85.211:3002/payment';
// const news_BASE_URL = 'https://51.79.85.211:3002/news';
// const testimonial_URL = 'https://51.79.85.211:3002'
// const membership_API_URL = "https://51.79.85.211:3002/membership"
// const SETTING_URL = "https://51.79.85.211:3002/setting"
// export const baseUrl = "https://51.79.85.211:3002/"

const API_BASE_URL = 'https://api.corelaser.com/auth';
const Payment_BASE_URL = 'https://api.corelaser.com/payment';
const news_BASE_URL = 'https://api.corelaser.com/news';
const testimonial_URL = 'https://api.corelaser.com'
const membership_API_URL = "https://api.corelaser.com/membership"
const SETTING_URL = "https://api.corelaser.com/setting"
export const baseUrl = "https://api.corelaser.com/"

//Local

// const API_BASE_URL = 'http://198.168.1.49:3002/auth';
// const Payment_BASE_URL = 'http://198.168.1.49:3002/payment';
// const news_BASE_URL = 'http://198.168.1.49:3002/news';
// const testimonial_URL = 'http://198.168.1.49:3002';
// const membership_API_URL = "http://198.168.1.49:3002/membership"
// const SETTING_URL = "http://198.168.1.49:3002/setting"
// export const baseUrl = "http://198.168.1.49:3002/"

export const signIn = (endPoint, data) => {
  console.log(endPoint, data);
  return fetch(`${API_BASE_URL}/${endPoint}`, {

    headers: {
      Accept: "application/json",
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data)
  }).then((data) => data.json())

};

export const addNewsLatter = (endPoint, data) => {
  console.log(endPoint, data);
  return fetch(`${news_BASE_URL}/${endPoint}`, {

    headers: {
      Accept: "application/json",
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data)
  }).then((data) => data.json())

};

export const getTerminal = (endPoint, data) => {
  console.log(`${Payment_BASE_URL}/${endPoint}`, data, "oky");
  return fetch(`${Payment_BASE_URL}/${endPoint}`, {

    headers: {
      Accept: "application/json",
      'Content-Type': 'application/json',
    },
    method: 'GET',
  }).then((data) => data.json())

};

export const MakePaymentWeb = (endPoint, data) => {
  console.log("Log from helper ->>", endPoint, data);


  return fetch(`${Payment_BASE_URL}/${endPoint}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: data,
    method: 'POST',
  }).then((data) => data.json())

};

export const signUp = async (endPoint, data) => {

  const formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key]);
  }

  console.log(endPoint, data);
  return fetch(`${API_BASE_URL}/${endPoint}`, {

    method: 'POST',
    body: formData,
  }).then((data) => data.json())
};

export const forgotPassword = async (endPoint, data) => {

  return fetch(`${API_BASE_URL}/${endPoint}`, {

    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  }).then((data) => data.json())
};

export const verifyOTP = async (endPoint, data) => {
  return fetch(`${API_BASE_URL}/${endPoint}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  }).then((data) => data.json())
};

export const resetPassword = async (endPoint, data) => {
  return fetch(`${API_BASE_URL}/${endPoint}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  }).then((data) => data.json())
};

export const testimonials = (endPoint) => {
  console.log("THIS IS ENDPOINT -- : ", `${testimonial_URL}/${endPoint}`);

  return fetch(`${testimonial_URL}/${endPoint}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

    },
    method: "GET",
  }).then((data) => data.json());
};

export const membershipList = (endPoint) => {

  return fetch(`${membership_API_URL}/${endPoint}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",

    },
    method: "GET",
  }).then((data) => data.json());
};

export const getSetting = (endPoint) => {
  console.log("THIS IS ENDPOINT -- : ", `${SETTING_URL}/${endPoint}`);

  return fetch(`${SETTING_URL}/${endPoint}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "GET",
  }).then((data) => data.json());
};
