import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'

const Business = () => {
    return (
        <>
            <Header />
            <main id='main'>

                <section className="hero-section inner-page">
                    <div className="wave">

                        <svg width="1920px" height="265px" viewBox="0 0 1920 265" version="1.1">
                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
                                    <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,667 L1017.15166,667 L0,667 L0,439.134243 Z" id="Path"></path>
                                </g>
                            </g>
                        </svg>

                    </div>

                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <div className="row justify-content-center">
                                    <div className="col-md-12 text-center hero-text">
                                        <h1 data-aos="fade-up" data-aos-delay="">CoreLaser for your employees</h1>
                                        <p className="mb-5" data-aos="fade-up" data-aos-delay="100">To request more information and a free trial for your business and employees.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="section my-5">
                    <div className="container">


                        <div className="row justify-content-center">

                            <div className="col-md-8 mb-5 mb-md-0" data-aos="fade-up">
                                <form action="forms/contact.php" method="post" role="form" className="php-email-form">

                                    <div className="row">
                                        <div className="col-md-6 form-group">
                                            <label for="name">First Name</label>
                                            <input type="text" name="name" className="form-control" id="name" required />
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label for="name">Last Name</label>
                                            <input type="text" name="name" className="form-control" id="name" required />
                                        </div>
                                        <div className="col-md-12 form-group mt-3 mt-md-0">
                                            <label for="name">Email</label>
                                            <input type="email" className="form-control" name="email" id="email" required />
                                        </div>
                                   
                                        <div className="col-md-12 form-group mt-3">
                                            <label for="name">Message</label>
                                            <textarea className="form-control" name="message" required></textarea>
                                        </div>

                                        <div className="col-md-12 mb-3">
                                            <div className="loading">Loading</div>
                                            <div className="error-message"></div>
                                            <div className="sent-message">Your message has been sent. Thank you!</div>
                                        </div>

                                        <div className="col-md-12 form-group">
                                            <input type="submit" className="btn btn-primary d-block w-100" value="Send Message" />
                                        </div>
                                    </div>

                                </form>
                            </div>

                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default Business
