import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';

function ResetPassword({ error, show, onHide, changePassword, email }) {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showValidation, setShowValidation] = useState(false);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleResetPassword = () => {

        if (!password || !confirmPassword) {
            setShowValidation(true)
        } else {

            if (password === confirmPassword) {

                changePassword({ email: email, password: password })
                console.log('Password reset successful');
                onHide(); // Close the modal
                setPassword("")
                setConfirmPassword("")

            } else {
                // Passwords do not match, show validation error
                setShowValidation(true);
            }
        };
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Password Reset</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="password">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter new password"
                            value={password}
                            onChange={handlePasswordChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="confirmPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Confirm password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                        />
                    </Form.Group>
                </Form>
                {showValidation && (
                    <Alert variant="danger">
                        Please make sure your passwords match or not blank.
                    </Alert>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleResetPassword}>
                    Reset Password
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ResetPassword;
