import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import CTA from '../components/CTA';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Hero from '../components/Hero';
import HomeAbout from '../components/HomeAbout';
import HomeFeature from '../components/HomeFeature';
import Testimonials from '../components/Testimonials';
import { baseUrl } from '../utils/helper';

export const socket = io(baseUrl);


const Home = () => {
    const navigate = useNavigate()
    const [messages, setMessages] = useState([]);
    const openedUrls = new Set(); // Initialize openedUrls as a Map
    let popupWindow = null;
let openPopupWindowDebounced = null;
const debounce = (func, delay) => {
    let timerId;
    return (...args) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
};
    useEffect(() => {
        window.scrollTo(0, 0)

    }, []);

    const takeToTop = (redirectTo) => {

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
        if (redirectTo === "home") {
            navigate('/')
        } else if (redirectTo === "about") {
            navigate('/about')
        } else if (redirectTo === "pricing") {
            navigate('/pricing')
        } else {
            navigate('/contact')
        }
    }

    socket.on('connect-to-web-android', (userId) => {
        console.log("connect-to-web-android");
        if (!localStorage.getItem('userID')) {
            alert("please login")
        } else if (localStorage.getItem('userID') !== userId) {
            // alert("user id not match of web and app")
        } else {
            const url = `/connect-to-app/android/${userId}`;
            openPopupWindowDebounced(url);
        }
    });
    
    socket.on('connect-to-web-ios', (userId) => {
        if (!localStorage.getItem('userID')) {
            alert("please login")
        } else if (localStorage.getItem('userID') !== userId) {
            // alert("user id not match of web and app")
            return
        } else {
            const url = `/connect-to-app/ios/${userId}`;
            openPopupWindowDebounced(url);
        }
    });
    const openPopupWindow = (url) => {
        if (openedUrls.has(url)) {
            if (popupWindow && !popupWindow.closed && popupWindow.location.href === url) {
                popupWindow.focus();
            } else {
                if (popupWindow && !popupWindow.closed) {
                    popupWindow.close();
                }
                popupWindow = window.open(url, '_blank', 'height=' + window.screen.height + ', width=' + window.screen.width);
                openedUrls.add(url);
            }
            return;
        }
    
        popupWindow = window.open(url, '_blank', 'height=' + window.screen.height + ', width=' + window.screen.width);
    
        if (!popupWindow || popupWindow.closed || typeof popupWindow.closed === 'undefined') {
            alert('Popup blocked! Please disable your pop-up blocker for this site to use this feature.');
            popupWindow = null;
        } else {
            openedUrls.add(url);
        }
    }
    // const openPopupWindow = (url) => {
    //     const existingTab = openedUrls.get(url);
    //     if (existingTab) {
    //         // If the URL is already opened, focus on that tab
    //         existingTab.focus();
    //     } else {
    //         // Open the URL in a new tab
    //         const newTab = window.open(url, '_blank');
    //         if (newTab) {
    //             openedUrls.set(url, newTab);
    //         } else {
    //             alert('Failed to open a new tab. Please check your browser settings.');
    //         }
    //     }
    // }
    openPopupWindowDebounced = debounce(openPopupWindow, 500);
    return (
        <div>
            <Header takeToUp={takeToTop} />
            <Hero />
            <main id="main">
                <HomeAbout />
                <HomeFeature />
                <Testimonials />
                <CTA />
            </main>
            <Footer takeToUp={takeToTop} />
        </div>
    );
}

export default Home
