import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { io } from 'socket.io-client';
import Canvas4 from '../components/Canvas4'
import { socket } from './Home';
let getFirstData = 0
const Level4 = () => {
    const [data, setData] = useState({ x: 0, y: 0 })
    const [difficulty, setDifficulty] = useState(1)
    const [device,setDevice]=useState('')
    let [calibrationData, setCalibrationData] = useState({ x: 0, y: 0 })
    const navigate = useNavigate()
    useEffect(() => {
        if(!localStorage.getItem("email")){
            navigate('/sign-in')
        }
        socket.on('difficulty-android'+localStorage.getItem('userID'), (data) => {
            console.log(data, 'difficulty in lebeval');
            setDifficulty(data)
        });
        socket.on('calibrate-android', (data) => {
            setDevice('android')
            // console.log(data, 'calibrate');
            if (getFirstData === 0) {
                setCalibrationData(data)
                getFirstData = 1
            }
            setData(data)
        });
        socket.on('disconnect-level-android', () => {

            navigate('/')
        });

        socket.on('difficulty-ios'+localStorage.getItem('userID'), (data) => {
            console.log(data, 'difficulty in lebeval');
            setDifficulty(data)
        });
        socket.on('calibrate-ios', (data) => {
            setDevice('ios')
            // console.log(data, 'calibrate');
            if (getFirstData === 0) {
                setCalibrationData(data)
                getFirstData = 1
            }
            setData(data)
        });
        socket.on('disconnect-level-ios', () => {

            navigate('/')
        });
        return () => {
            getFirstData = 0
        }
    }, []);
    return (
        <Canvas4 dotData={data} device={device} difficulty={difficulty} calibrationData={calibrationData} />
    )
}

export default Level4
